import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { httpServiceMiddleware } from '../../../config/httpService';
import { TOKEN_NAME } from '../../../env';
import { formatCurrency } from '../../../utils/currency'; // Ensure you have this utility function
import CheckoutModal from '../../CheckoutModal';
import BuyButton from '../BuyButton';
import * as S from './styles';

const TicketSelector = ({ product, checkoutModal, setCheckoutModal }) => {
  const [param] = useSearchParams();
  const [selectedTickets, setSelectedTickets] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (param.get('quantity')) {
      setSelectedTickets(param.get('quantity'));
    }
  }, [param]);

  const handleTicketSelect = useCallback(
    (amount) => {
      setSelectedTickets((prev) => {
        const newQuantity = prev + amount;
        if (newQuantity > product.maxQuantity) {
          return Number(product.maxQuantity);
        }
        if (newQuantity < product.minQuantity) {
          return Number(product.minQuantity);
        }

        return newQuantity;
      });
    },
    [product],
  );

  const incrementTickets = useCallback(() => {
    setSelectedTickets((prev) => {
      const newQuantity = prev + 1;

      if (newQuantity > product.maxQuantity) {
        return Number(product.maxQuantity);
      }

      if (newQuantity < product.minQuantity) {
        return Number(product.minQuantity);
      }

      return newQuantity;
    });
  }, [product]);

  const decrementTickets = useCallback(() => {
    setSelectedTickets((prev) => {
      const newQuantity = prev > 0 ? prev - 1 : 0;

      if (newQuantity > product.maxQuantity) {
        return Number(product.maxQuantity);
      }

      if (newQuantity < product.minQuantity) {
        return Number(product.minQuantity);
      }

      return newQuantity;
    });
  }, [product]);

  const handleInputChange = useCallback(
    (e) => {
      const value = e.currentTarget.value;

      if (value > product.maxQuantity) {
        setSelectedTickets(Number(product.maxQuantity));
        return;
      }

      if (value < product.minQuantity) {
        setSelectedTickets(Number(product.minQuantity));
        return;
      }
      setSelectedTickets(Number(value));
    },
    [product],
  );

  const navigate = useNavigate();

  const navigateToCheckout = async () => {
    const token = localStorage.getItem(TOKEN_NAME);

    if (!token) {
      setCheckoutModal(true);
      return;
    }

    try {
      Swal.fire({
        title: 'Aguarde...',
        text: 'Estamos reservando seus tickets',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      setLoading(true);
      const { data } = await httpServiceMiddleware.post('/rifas/reserveds', {
        id: product.id,
        quantity: selectedTickets,
      });
      navigate(`/checkout/${data.reserveId}`);
    } catch (error) {
      let errorMessage = 'Ocorreu um erro inesperado';
      if (error.response.data) {
        errorMessage = error.response.data.error;
      }

      Swal.fire('Oops!', errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => setCheckoutModal(false);

  useEffect(() => {
    if (product.minQuantity) {
      setSelectedTickets(Number(product.minQuantity));
    }
  }, [product]);

  return (
    <S.Container>
      <S.Title>Selecione a quantidade 📣</S.Title>
      <S.OptionsContainer>
        {[50, 100, 200, 400].map((amount) => (
          <S.TicketOption
            key={amount}
            onClick={() => handleTicketSelect(amount)}
          >
            <S.TicketAmount>+{amount}</S.TicketAmount>
            <S.TicketSelectButton>SELECIONAR</S.TicketSelectButton>
          </S.TicketOption>
        ))}
      </S.OptionsContainer>
      <S.QuantityControls>
        <S.DecrementButton onClick={decrementTickets}>-</S.DecrementButton>
        <S.CurrentQuantity
          value={selectedTickets}
          onChange={handleInputChange}
        />
        <S.IncrementButton onClick={incrementTickets}>+</S.IncrementButton>
      </S.QuantityControls>

      {product && (
        <BuyButton
          disabled={loading}
          loading={loading}
          price={formatCurrency(product.price * selectedTickets)}
          onClick={() => navigateToCheckout()}
        />
      )}
      {checkoutModal && (
        <CheckoutModal
          isOpen={checkoutModal}
          close={onClose}
          quantity={selectedTickets}
        />
      )}
    </S.Container>
  );
};

export default TicketSelector;
