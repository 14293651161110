import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 2em 2em;
  background: #fff;

  background: var(--GreyBg, #f9f9f9);
`;

export const Title = styled.h2`
  color: var(--Black, #17181a);
  font-size: 16px;
  font-style: 600;
  text-align: left;
  width: 100%;
  margin-bottom: 1.2em;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 16px;
  align-items: stretch;
  flex-direction: row;
  align-content: space-around;
`;

export const TicketOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 47%;
  padding: 1em 1em;
  background: #fff;
  color: #000;
  border-radius: 11px;
  box-shadow: 0px -3px 12px rgb(191 216 210 / 39%);
  cursor: pointer;
  transition: transform 0.2s;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.02);
    background-color: #00bc9a;

    p {
      display: none;
    }

    span {
      font-size: 32px;
      color: #fff;
    }
  }

  &:active {
    background: #e7e7e7;
  }
`;

export const TicketAmount = styled.span`
  font-size: 25px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
`;

export const TicketSelectButton = styled.p`
  font-size: 10px;
  color: #59595970;
  border: none;
  letter-spacing: 2px;
  border-radius: 20px;
  padding: 4px 12px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
`;

export const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 1em 0;
`;

export const DecrementButton = styled.button`
  border-radius: 120px;
  font-size: 32px;
  height: 65px;
  width: 65px;
  border: 0.902px solid rgba(255, 255, 255, 0.17);
  background-color: #fff;
  box-shadow: 0px 1.988px 11.93px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(14.912940979003906px);
  transition: all 0.3s ease;

  &:hover {
    background-color: #ddd;
  }
`;

export const CurrentQuantity = styled.input`
  font-size: 20px;
  color: #333;
  border-radius: 12px;
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 55px;
  width: 70%;
  border-radius: 17px;
  background: var(--White, #fff);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  color: #000;
  text-align: center;
  border: none;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const IncrementButton = styled(DecrementButton)`
  border-radius: 120px;
  height: 65px;
  width: 65px;
  border-radius: 120px;
  border: 0.902px solid rgba(255, 255, 255, 0.17);
  background: #fff;
  box-shadow: 0px 1.988px 11.93px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(14.912940979003906px);
`;
