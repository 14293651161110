// src/components/BuyButton/styles.js
import styled from 'styled-components';

export const Button = styled.button`
  color: #fff;
  padding: 1.4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 18px;
  background: var(--Green, #00bc9a);
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-top: 1em;
  svg {
    font-size: 24px;
  }

  &:hover {
    background-color: #00bc9a; // A darker shade of green for the hover state
    border: 1px solid #00bc9a;
  }

  &:active {
    transform: scale(0.98); // Slightly shrink the button when clicked
  }
`;
