import React from 'react';
import {
  PiCopyDuotone,
  PiTelegramLogoDuotone,
  PiWhatsappLogoDuotone,
} from 'react-icons/pi';
import * as S from './styles';

const ShareComponent = () => {
  const currentUrl = window.location.href;

  // const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
  //   currentUrl,
  // )}`;
  const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
    currentUrl,
  )}`;
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(currentUrl)}`;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        alert('Link copiado para a área de transferência!');
      })
      .catch((err) => {
        console.error('Erro ao copiar o link:', err);
      });
  };

  return (
    <>
      <S.SortedBar>
        {/* <S.SortedText> Sorteio: 05/04/2024</S.SortedText> */}

        <S.ShareContainer>
          <S.Title>Share</S.Title>

          <S.ShareButton href={telegramUrl} target='_blank' color='#0088cc'>
            <PiTelegramLogoDuotone />
          </S.ShareButton>
          <S.ShareButton href={whatsappUrl} target='_blank' color='#25D366'>
            <PiWhatsappLogoDuotone />
          </S.ShareButton>
          <S.ShareButton as='button' onClick={copyToClipboard} color='#606060'>
            <PiCopyDuotone />
          </S.ShareButton>
        </S.ShareContainer>
      </S.SortedBar>
    </>
  );
};

export default ShareComponent;
