// src/components/OrderSummary/styles.js
import styled from 'styled-components';

export const SummaryContainer = styled.div`
  background: #ffffff;
  border-radius: 21px;
  padding: 2em;
  box-shadow: 0px 5px 15px rgba(207, 208, 255, 0.31);
  margin-bottom: 2.8rem;
  cursor: pointer;
`;

export const SummaryTitle = styled.h2`
  font-size: 21px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

export const TicketInfo = styled.div`
  background: #91fddb;
  border-radius: 8px;
  padding: 5px 8px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;

  width: fit-content;
  justify-content: space-between;
`;

export const TicketText = styled.span`
  color: #333;
  font-weight: bold;
  font-size: 15px;
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ProductImage = styled.img`
  border-radius: 15px;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

export const ProductDetails = styled.div``;

export const ProductName = styled.h3`
  color: #000;
  margin: 0;
  margin-bottom: 0.5em;
  font-size: 18px;
  font-weight: 600;
`;

export const ProductDescription = styled.p`
  color: #666;
  font-size: 10px;
  margin-top: 0.8em;
  margin: 0;
`;

export const QuantityPrice = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const PriceCalculation = styled.p`
  font-size: 1.2em;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-top: 3px dashed #f7f7f7;

  padding-top: 20px;
`;

export const TotalPrice = styled.p`
  font-size: 1.5em;
  color: #333;
  text-align: center;
`;

export const SummaryButton = styled.button`
  background: #91fddb;
  border-radius: 10px;
  padding: 18px 15px;
  margin-top: 1em;
  border: none;
  width: 100%;
  color: #222;
  font-weight: bold;
  cursor: pointer;
`;

export const ViewMoreButton = styled.button`
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
  background-color: #000;
  color: #fff;
  border: none;
  font-size: 15px;
  padding: 1.3em 2em;
  margin-top: 0.5em;
  box-shadow: 0px 5px 15px rgba(207, 208, 255, 0.31);
  margin-bottom: 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  border: 1px solid #fff;

  svg {
    font-size: 21px;
  }

  transition: all 0.3s ease;
  &:hover {
    scale: 1.1;
    border: 1px solid #000;
    background-color: #000;
    color: #ffffff90;
  }
`;
