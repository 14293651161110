import styled from 'styled-components';
const envelopeIcon = '/icons/crm.svg';
const lockIcon = '/icons/lock.svg';
const userIcon = '/icons/user.svg';

const icons = {
  envelope: envelopeIcon,
  user: userIcon,
  lock: lockIcon,
};

export const Body = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HeadingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  z-index: 1;
`;

export const Title = styled.h2`
  color: #000;
  text-align: center;

  font-size: 21px;
  font-style: normal;
  margin: 0;
  font-weight: 500;
  line-height: 21px; /* 80.769% */
`;

export const SubTitle = styled.h5`
  color: #000;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  margin: 0;
  font-weight: 300;
  margin-bottom: 1em;
  line-height: normal;
`;
export const ForgotPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
  font-size: 12px;

  margin-top: 0.4em;

  svg {
    font-size: 16px;
  }
`;
export const SendButton = styled.button`
  display: flex;
  width: 100%;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  border: 0.925px solid #000;
  background: var(--Green, #000);
  box-shadow: 0px 0px 8.651px 0px rgba(0, 0, 0, 0.05);
  height: 50px;
  padding: 19px 33px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
  border: none;

  &:hover {
    box-shadow: none;
    color: #000;
    background-color: #fff;
    transition: all 0.3s ease;
    border: 0.925px solid #000;
  }

  svg {
    font-size: 28px;
  }
`;

export const Logo = styled.img`
  width: 230px;
  z-index: 1;
  height: auto;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2em;
`;

export const LogoBackgroundAvatar = styled.img`
  width: auto;
  height: 100%;
  position: absolute;
  z-index: 0;
  bottom: 0px;
  right: 0px;
`;

export const Forms = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  justify-content: center;
  z-index: 1;
  align-items: center;

  @media screen and (max-width: 600px) {
    max-width: 100%;
    padding: 0;
  }

  a {
    color: #000;
    font-size: 14px;
    font-style: normal;
    margin: 0 0 15px auto;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const InputGroup = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 22px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 1.4em;
  padding-left: 60px; // Make space for the icon
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 30px;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #dfdfdf;
  background: var(--White, #fff);
`;
