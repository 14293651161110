// src/components/MobileMenu/styles.js
import styled from 'styled-components';

export const Container = styled.nav`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em 3em;
  z-index: 1000;
  border-radius: 45px 45px 0px 0px;
  background: #fff;
  box-shadow: 0px -6px 10px 0px rgba(139, 139, 139, 0.1);
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  color: ${({ isActive }) =>
    isActive ? '2px solid #000' : '2px solid #707070'};
`;

export const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  height: 58px;
  width: 58px;
  cursor: pointer;
  transition: all 0.3s ease;
  // Adicione um contorno ou outra estilização para o estado ativo
  border: ${({ isActive }) =>
    isActive ? '2px solid #000' : '2px solid #f9f9f9'};
  background: ${({ isActive }) => (isActive ? ' #000' : 'none')};
  border-radius: 50%;

  svg {
    color: ${({ isActive }) => (isActive ? '#fff' : '#000')};
    font-size: 26px;
  }
  &:hover {
    border: 2px solid #ffffff30;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
