import React from 'react';

import { useLocation } from 'react-router-dom';
import AccountToggle from '../AccountToggle';
import * as S from './styles';

// Mock functions to simulate API calls
const mockApiCall = (data) => {
  console.log(data);
  return true;
};

const CheckoutModal = ({ isOpen, close, quantity }) => {
  const location = useLocation();

  const redirect = location.pathname;

  if (!isOpen) return null;

  return (
    <>
      <S.ModalBackdrop onClick={close} />
      <S.ModalWrapper>
        <AccountToggle
          callback={close}
          redirect={redirect}
          quantity={quantity}
        />
      </S.ModalWrapper>
    </>
  );
};

export default CheckoutModal;
