import * as S from './styles';

import { AiTwotoneMail } from 'react-icons/ai';

const ChatContent = () => {
  return (
    <S.Container>
      <S.SupportContainer>
        <S.Title>Fale com o nosso suporte </S.Title>
        {/* 
        <S.SupportEmailButton
          onClick={() => (window.location = 'mailto:support@rifamilgrau.com')}
        >
          Falar via WhatsApp
          <PiChatsCircleDuotone />
        </S.SupportEmailButton> */}

        <S.SupportEmailButton
          onClick={() =>
            (window.location = 'mailto:claytaosantos@rifamilgrau.com')
          }
        >
          {/* Replace 'email-icon.png' with the path to your email icon */}
          Abra um chamado pro email
          <AiTwotoneMail />
        </S.SupportEmailButton>
      </S.SupportContainer>
    </S.Container>
  );
};

export default ChatContent;
