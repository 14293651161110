// src/components/Raffle/RaffleDetail.js
import React from 'react';
import { PiTicketDuotone } from 'react-icons/pi';
import styled, { keyframes } from 'styled-components';
const imageRaffle = '/assets/raffles/rifacb.png';
const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.2; }
`;

const Badge = styled.div`
  border-radius: 15.366px;
  border: 0.42px solid var(--Green, #eb1943);
  background: #eb1943;
  padding: 4px 16px;
  position: absolute;
  top: 2em;
  left: 2em;
  font-size: 14px;
  z-index: 2;
  color: #fff;
  animation: ${blink} 1s linear infinite;
`;

const DetailContainer = styled.div`
  margin: auto;
  padding: 0 0 0em 0;
  max-width: 640px;
`;

const Title = styled.h1`
  font-size: 23px;
  margin-bottom: 0.3em;
  color: #fff;
`;

const Description = styled.p`
  font-size: 18px;
  color: #fff;
`;

export const Currency = styled.span`
  font-size: 12px;
  margin-right: 2px;
  color: #000;
  font-weight: 500;
`;

const RafflePricing = styled.p`
  font-size: 28px;
  text-align: right;
  color: #000;
  font-weight: 700;
`;

const PriceInfo = styled.div`
  font-size: 24px;
  color: #333;
  background: #ffffff90;
  width: fit-content;
  padding: 0.4em;
  gap: 0.2em;
  text-align: right;
  display: flex;
  border-radius: 13px;
  align-items: flex-end;
  backdrop-filter: blur(10px);
  position: absolute;
  right: 1em;
  top: 1em;
  flex-direction: column;
`;

const Strong = styled.strong`
  color: #000;
  text-align: right;
  width: 100%;
  font-size: 11px;
  font-weight: 400;
`;

const ImageGalleryContainer = styled.div`
  margin-top: 0px;
  position: relative;
`;

const ThumbnailList = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.2em;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 400px;
  border: none;
  object-fit: cover;

  &:hover {
    border-color: #aaa;
  }
`;

const FullImage = styled.img`
  width: 100%;
  max-width: 600px;
`;

export const ProductBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
`;
export const Infos = styled.div`
  padding: 3em;
  width: 100%;
`;

const RaffleDetail = ({ raffle }) => {
  console.log('raffle', raffle);
  return (
    <DetailContainer>
      <ImageGalleryContainer>
        <Badge> ⚡ Acabou de sair</Badge>

        <PriceInfo>
          <PiTicketDuotone />

          {/* <Strong>Por apenas:</Strong> */}
          <RafflePricing>
            <Currency>R$</Currency>
            {raffle.price}
          </RafflePricing>
        </PriceInfo>

        <ProductBar>
          <Infos>
            <Title>{raffle.title}</Title>
            <Description>{raffle.description}</Description>
          </Infos>
        </ProductBar>
        {raffle.medias.data.length && (
          <ThumbnailList>
            {raffle.medias.data.map((image, index) => (
              // <Thumbnail key={index} src={image?.attributes?.url} />
              <Thumbnail key={index} src={imageRaffle} />
            ))}
          </ThumbnailList>
        )}
      </ImageGalleryContainer>
    </DetailContainer>
  );
};

export default RaffleDetail;
