import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  position: fixed;
  z-index: 1040;
  top: -5px;
  left: -5px;
  width: 102%;
  height: 102%;
  background-color: #00000099;
  backdrop-filter: blur(10px);
`;

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1050;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 3em;
  border-radius: 21px;
  width: 95%;
  max-width: 500px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
  border-bottom: 1px solid #dee2e6;
`;

export const ModalBody = styled.div`
  padding: 20px 0;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #dee2e6;
`;

export const Button = styled.button`
  border-radius: 5px;
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &.primary {
    background-color: #4caf50;
    color: white;
  }

  &.secondary {
    background-color: #f1f1f1;
  }
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;
