import styled from 'styled-components';

export const Title = styled.h2`
  text-align: center;
  margin-right: 12px;
  color: #707070;
  width: fit-content;
  font-weight: 500;
`;

export const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const ShareButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 5px;
  border: none;
  background: ${(props) => props.color || '#007bff'};
  color: white;
  text-decoration: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 24px;

  &:hover {
    opacity: 0.8;
  }
`;

export const SortedText = styled.p`
  font-size: 15px;
  background-color: #fff;
  padding: 0.8em;
  width: fit-content;
  border-radius: 12px;
`;
export const SortedBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  padding: 2em;
`;
