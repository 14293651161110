import React from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { httpServiceMiddleware } from '../../../config/httpService';
import { TOKEN_NAME } from '../../../env';
import * as S from './styles';

const PromotionDetails = ({ raffle, setCheckoutModal }) => {
  const navigate = useNavigate();

  const transformToBrl = (price) => {
    const toBrl = parseFloat(price).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    });

    return toBrl;
  };

  const navigateToCheckout = async (block) => {
    const token = localStorage.getItem(TOKEN_NAME);

    if (!token) {
      setCheckoutModal(true);
      return;
    }

    try {
      Swal.fire({
        title: 'Aguarde...',
        text: 'Estamos reservando seus tickets',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const { data } = await httpServiceMiddleware.post('/rifas/reserveds', {
        id: raffle.id,
        block,
      });

      navigate(`/checkout/${data.reserveId}`);
    } catch (error) {
      let errorMessage = 'Ocorreu um erro inesperado';
      if (error.response.data) {
        errorMessage = error.response.data.error;
      }

      Swal.fire('Oops!', errorMessage, 'error');
    } finally {
      Swal.close();
    }
  };

  return (
    <S.PromotionContainer>
      <S.PromotionTitle>Recomendados para você 💰</S.PromotionTitle>
      <S.DealBox>
        {raffle?.block?.map((deal, index) => (
          <S.DealContainer
            key={index}
            onClick={() => navigateToCheckout(deal.id)}
          >
            <S.DealText>{deal.quantity} por</S.DealText>
            <S.DealPrice>
              <S.Currency>R$</S.Currency>
              {transformToBrl(deal.price)}
            </S.DealPrice>
          </S.DealContainer>
        ))}
      </S.DealBox>
    </S.PromotionContainer>
  );
};

export default PromotionDetails;
