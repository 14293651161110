import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TOKEN_NAME } from "../../env";
import { httpServiceCms } from "../../config/httpService";
import Swal from "sweetalert2";

function Blank({ children, isPublic }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Função para rolar para o topo
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    scrollToTop();

    window.onpopstate = scrollToTop;

    return () => {
      window.onpopstate = null;
    };
  }, [location]);

  const hangleGetMe = useCallback(async () => {
    try {
      const token = localStorage.getItem(TOKEN_NAME);

      if (!token) throw new Error('Token not found');

      const { data } = await httpServiceCms.get('/users/me', {
        params: {
          populate: 'role',
        },
      });

      if (location.pathname?.includes('/admin')) {
        if (data.role.id !== 4) {
          return navigate('/account/menu');
        }
      }
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Sua sessão expirou, faça login novamente!',
      });

      localStorage.removeItem(TOKEN_NAME);
      window.location.href = '/';
    }
  }, [isPublic]);

  useEffect(() => {
    !isPublic && hangleGetMe()
  }, [isPublic])

  return children;
}

export default Blank;
