// src/components/LastTickets/index.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

const MyLastTicketsCreated = ({ products }) => {
  const navigate = useNavigate();

  const handleGoToDetail = (id) => {
    navigate(`/admin/my-raffles/insights/${id}`);
  };

  return (
    <>
      <S.Title>Minhas Rifas</S.Title>

      <S.LastTickets>
        {products &&
          products.map((product) => (
            <S.TicketCard
              onClick={() => handleGoToDetail(product.id)}
              backgroundImage={
                product.medias.data !== null
                  ? product.medias.data[0].attributes.url
                  : ticket.image
              }
            >
              <S.TicketInfo>
                <S.Data>
                  <S.TicketTitle>{product.title}</S.TicketTitle>
                  <S.TicketDescription>
                    {product.description}
                  </S.TicketDescription>
                </S.Data>
              </S.TicketInfo>
              <S.TicketCardBefore />
            </S.TicketCard>
          ))}
      </S.LastTickets>
    </>
  );
};

export default MyLastTicketsCreated;
