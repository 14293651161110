import styled from 'styled-components';

export const Logo = styled.img`
  width: 25%;
  z-index: 1;
  height: auto;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;

export const Branding = styled.img`
  width: 35%;
  z-index: 1;
  height: auto;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const Space = styled.div`
  width: 100%;
  margin-bottom: 7em;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  background: #000;
  transition: top 0.3s;
  z-index: 100;
  position: relative; // Make the header fixed to the top: ;
`;

export const WelcomeText = styled.p`
  background: #ffffff;
  color: #4f4f4f;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  padding: 10px;
  font-size: 15px;
  border-bottom: 1px solid #eaeaea;
  svg {
    font-size: 20px;
  }
`;
