// src/components/PromotionDetails/styles.js
import styled from 'styled-components';

export const PromotionContainer = styled.section`
  padding: 2em 2em 2.5em;
  border-radius: 0px;
  background: #e6fff9;
`;

export const PromotionTitle = styled.h2`
  color: var(--Black, #17181a);
  font-size: 16px;
  font-style: 600;
  text-align: left;
  width: 100%;
  margin-bottom: 1em;
`;
export const DealBox = styled.div`
  display: flex;
  flex-wrap: wrap; // Ensure that children can wrap to the next line
  gap: 0.7em; // This will ensure even spacing on all sides
  justify-content: space-between; // Align children to the start of the main axis
`;

export const DealContainer = styled.div`
  flex: 1 1 calc(49% - 0.1em); // This allows for two items per row accounting for the gap
  max-width: calc(
    49% - 0.1em
  ); // This prevents any item from becoming larger than half the parent's width minus the gap
  background: #00bc9a;
  padding: 1.5em 1.5em;
  border-radius: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: all 0.3s ease;
  text-align: center; // Centers the text when the flex items shrink

  &:hover {
    opacity: 0.9;
    transform: scale(
      1.01
    ); // Use transform instead of scale for the hover effect
  }
`;

export const DealText = styled.span`
  font-size: 17px;
  color: #fff;
`;

export const Currency = styled.p`
  font-size: 8px;
`;

export const DealPrice = styled.span`
  font-size: 17px;
  display: flex;
  align-items: center;
  gap: 0.3em;
  font-weight: bold;
  color: #fff;
`;

// Add more styled components as necessary for other parts of the promotion details.
