// src/pages/ProductPage.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PromotionDetails from '../../../components/Raffle/PromotionDetails';
import RaffleDetail from '../../../components/Raffle/RaffleDetail';
import TicketSelector from '../../../components/Raffle/TicketSelector';
import ShareComponent from '../../../components/ShareComponent';
import { httpServiceMiddleware } from '../../../config/httpService';

const ProductPage = () => {
  const { id } = useParams();
  const [checkoutModal, setCheckoutModal] = useState(false);

  const [product, setProduct] = useState(null);

  async function getRifa(id) {
    const { data } = await httpServiceMiddleware.get(`/rifas/${id}`);
    setProduct(data);
  }

  useEffect(() => {
    if (id) {
      getRifa(id);
    }
  }, [id]);

  return (
    <>
      {product ? (
        <>
          <RaffleDetail raffle={product} />
          <PromotionDetails
            raffle={product}
            setCheckoutModal={setCheckoutModal}
            checkoutModal={checkoutModal}
          />
          <TicketSelector
            product={product}
            setCheckoutModal={setCheckoutModal}
            checkoutModal={checkoutModal}
          />
          <ShareComponent />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default ProductPage;
