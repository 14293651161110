// src/components/BuyButton/index.js
import React from 'react';
import { PiDiamondsFourDuotone } from 'react-icons/pi';
import * as S from './styles';

const BuyButton = ({ price, disabled, loading, onClick }) => {
  return (
    <S.Button disabled={disabled} onClick={() => onClick()}>
      {loading ? 'Processando...' : `Comprar agora por ${price}`}{' '}
      <PiDiamondsFourDuotone />
    </S.Button>
  );
};

export default BuyButton;
