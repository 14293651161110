import styled from 'styled-components';

export const Body = styled.div`
  box-sizing: border-box;
  overflow: hidden;

  display: flex; /* Added display flex */
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HeadingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  justify-content: center;
  padding: 30px;
  align-items: center;
  z-index: 1;
  svg {
    font-size: 68px;
    color: #000;
    margin-bottom: 20px;
  }
`;

export const Title = styled.h3`
  width: 100%;
  font-size: 19px;
  text-align: center;
  color: black;
`;
export const SubTitle = styled.h5`
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  margin-top: 10px;
  font-weight: normal;
`;
export const Input = styled.input`
  border-radius: 15px;
  border: 1px solid #00000030;
  background: var(--White, #fff);
  padding: 0.1em 2em;
  min-height: 65px;

  box-sizing: border-box;
  display: flex;
  font-size: 16px;

  align-items: center;
  width: 100%;
  height: 50px;

  &::before {
  }

  input {
    border: none;
    background: transparent;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
`;

export const SendButton = styled.button`
  display: flex;
  width: 100%;
  color: #fff;
  font-size: 16px;
  min-height: 65px;
  font-style: normal;
  font-weight: 500;

  line-height: normal;
  cursor: pointer;
  border-radius: 15px;
  background: transparent;
  box-shadow: 0px 0px 8.651px 0px rgba(0, 0, 0, 0.05);
  height: 60px;
  padding: 19px 33px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
  border: none;
  margin-bottom: 3em;
  color: #00bc9a;
  border: 1px solid #00bc9a;
  &:hover {
    box-shadow: none;
    color: #fff;
    background-color: #00bc9a;
    transition: all 0.3s ease;
  }

  svg {
    font-size: 24px;
  }
`;

export const Logo = styled.img`
  width: 230px;
  z-index: 1;
  height: auto;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2em;
`;

export const LogoBackgroundAvatar = styled.img`
  width: auto;
  height: 100%;
  position: absolute;
  z-index: 0;
  bottom: 0px;
  right: 0px;
`;

export const Forms = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  justify-content: center;
  z-index: 1;
  align-items: center;
  max-width: 460px;

  @media screen and (max-width: 600px) {
    max-width: 90%;
  }

  a {
    color: #000;
    font-size: 14px;
    font-style: normal;
    margin: 0 0 15px auto;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
  }
`;
